<template>
  <div class="user-list-wrap">
    <p class="users"><b>{{ $t('users') }}</b></p>

    <div class="search-actions-wrap">
      <div class="search" v-if="hasPermission('users_view')">
        <div>
          <md-field>
            <img src="../../assets/images/search.svg" alt="search" class="search-icon">
            <md-input v-model="email" :placeholder="$t('email') " @input="filter"/>
          </md-field>
        </div>
        <div class="search-role">
          <md-field>
            <img src="../../assets/images/search.svg" alt="search" class="search-icon">
            <md-input v-model="role" :placeholder="$t('role') " @input="filter"/>
          </md-field>
        </div>
      </div>

      <div class="actions">
        <button
            class="btn-delete"
            @click="handlerDelete()"
            v-if="hasPermission('users_delete')"
            v-bind="{'disabled' : selected.length < 1}"
        >
          <img src="../../assets/images/delete.png" alt="arrow">
          <p>{{ $t('delete') }}</p>
        </button>
        <button class="btn-create" v-if="hasPermission('users_create')">
          <router-link to="/users/create">
            <p>{{ $t('create') }}</p>
          </router-link>
          <img src="../../assets/images/Path.png" alt="arrow">
        </button>
      </div>
    </div>

    <md-table>
      <md-table-row md-selectable="multiple" class="head_row">
        <md-table-head>

          <div class="permissions-wrap">
            <md-checkbox
                v-model="selectAll"
                @change="select"
            >
            </md-checkbox>
          </div>

        </md-table-head>


        <md-table-head
            v-for="(item, i) in  $t('userHeadData') "
            :key="i"
        >
          <md-ripple class="md-table-title">
            <div @click="customSorting(item.name, initialSort, i)">

              {{ item.title }}
              <img class="md-size-2x arrow_down" :class="`arrow_down_${i}`" src="../../assets/images/arrow_down.svg"/>
            </div>
          </md-ripple>
        </md-table-head>

        <md-table-head class="actions">{{ $t('actions') }}</md-table-head>
      </md-table-row>

      <md-table-row v-for="item in users" :key="item.id">
        <md-table-cell>
          <div class="permissions-wrap">
            <md-checkbox
                v-model="selected"
                :value="item.id"
            >
            </md-checkbox>
          </div>

        </md-table-cell>
        <md-table-cell>{{ item.name }}</md-table-cell>
        <md-table-cell>{{ $t(item.role_name) }}</md-table-cell>
        <md-table-cell>{{ item.email }}</md-table-cell>
        <md-table-cell>{{ item.created_at }}</md-table-cell>
        <md-table-cell>{{ item.updated_at }}</md-table-cell>
        <md-table-cell md-label="Actions" v-if="hasPermission('users_update') || hasPermission('users_delete')">
          <router-link
              :to="{ name: 'users-edit', params: { id: item.id }}"
          >
            <md-button class="md-dense md-raised" v-if="hasPermission('users_update')">
              <md-icon>edit</md-icon>
            </md-button>
          </router-link>
          <br>
          <md-button class="md-raised md-accent" @click="handlerDelete(item.id)" v-if="hasPermission('users_delete')">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <custom-pagination
        :list="users"
        :page="pagination.current_page"
        :pagesLength="pagesLength"
        :changePage="changePage"
    />
  </div>
</template>

<script>
import {userPermissions} from '@/mixins/userPermissions';
import {mapActions, mapGetters} from 'vuex';
import CustomPagination from "../../components/pagination/CustomPagination";

export default {
  name: 'user-list',
  components: {CustomPagination},
  data: () => ({
    selected: [],
    selectAll: false,
    filteredUsers: [],
    email: '',
    role: '',
    users: [],
    pagination: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      total: null
    },
    sortName: '',
    initialSort: "desc",
    sort: '',
    headData: [
      {title: 'Name', name: 'name', asc: false, desc: true},
      {title: 'Role', name: 'role_name', asc: false, desc: true},
      {title: 'Email', name: 'email', asc: false, desc: true},
      {title: 'Created at', name: 'created_at', asc: false, desc: true},
      {title: 'Updated at', name: 'updated_at', asc: false, desc: true},
    ],
  }),

  mixins: [
    userPermissions
  ],

  computed: {
    ...mapGetters(['usersList', 'reqPagination']),
    pagesLength() {
      return Math.ceil(+this.pagination.total / +this.pagination.per_page)
    }
  },


  async mounted() {
    await this.getPaginatedList({})
  },

  methods: {
    async select() {
      this.selected = [];
      if (this.selectAll) {
        for (let i in this.users) {
          this.selected.push(this.users[i].id);
        }
      }
    },

    async customSorting(sortName, sort, i) {
      if (this.initialSort === "desc") {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(180deg)'
        this.initialSort = "asc"
      } else {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(0deg)'
        this.initialSort = "desc"
      }
      this.headData.map(item => {
        if (sort === 'desc' && item.name === sortName) {
          item.desc = false
          item.asc = true
        } else {
          item.asc = false
          item.desc = true
        }
      })

      this.sortName = sortName
      this.sort = sort
      await this.getPaginatedList({
        page: this.pagination.current_page,
        name: this.name,
        sort: sortName,
        sortBy: sort
      })

    },

    getPaginatedList({page = 1, email = '', role = '', sort = '', sortBy = 'desc'}) {
      this.getUsersList({page, email, role, sort, sortBy})
          .then(data => {
            this.users = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
    },
    ...mapActions(['getUsersList', 'getDeleteUser']),
    changePage(currentPage) {
      this.getPaginatedList({
        page: currentPage,
        email: this.email,
        role: this.role,
        sort: this.sortName,
        sortBy: this.sort
      })
    },

    filter() {
      this.getPaginatedList({page: 1, email: this.email, role: this.role, sort: this.sortName, sortBy: this.sort})
    },

    onSelect(items) {
      let itemIds = [];
      items.map(item => {
        itemIds.push(item.id);
      });
      this.selected = itemIds.filter((item, pos) => {
        return itemIds.indexOf(item) === pos;
      });
    },

    getAlternateLabel(count) {
      let plural = '';

      if (count > 1) {
        plural = 's'
      }

      return `${count} user${plural} selected`
    },


    axiosHandler(id) {
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/users/" + id + "/delete")
          .then(() => {
            this.users = this.users.filter(el => el.id !== id)
            this.setUsersList(this.users);
          })
          .catch(error => console.log(error))
    },
    async handlerDelete(id = null) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }

      if (id != null) {
        this.axiosHandler(id)
      } else {
        let ids = this.selected;
        let actions = [];

        ids.map(id => {
          actions.push(
              this.axiosHandler(id)
          )
        });
        try {
          await Promise.all(actions)
        } catch (e) {
          console.log(e)
        }
      }

    },

    hasPermission(name) {
      return this.userPermissions.indexOf(name) >= 0
    },
  }
}
</script>

<style lang="scss">

.md-checkbox.md-theme-default.md-checked .md-checkbox-container {
  background-color: #054F63 !important;
}

.user-list-wrap {
  padding: 0 35px 0 20px;

  .md-input {
    padding-left: 50px !important;
    position: absolute;
    top: 8px;

  }

  .users {
    margin: 0 0 16px;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .actions {
    display: flex;
  }


  .md-table-title > div {
    color: #000000 !important;
    font-size: 16px;
    font-weight: bold;
  }

  .arrow_down:hover {
    cursor: pointer;
  }

  .md-table-title:hover {
    cursor: pointer;
  }

  .arrow_down {
    height: 18px !important;
    overflow: auto;
    transition: 0.25s ease;
  }

  .head_row {
    border-bottom: 1.5px solid #000000 !important;
  }

  .md-checkbox {
    margin: 8px 0 0 20px;
    display: inline-flex;
    position: relative;
    height: 18px;
    width: 18px;
    border: unset !important;
  }

  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary .md-icon-font, .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: #7f7878 !important;
    background-color: white;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: white;
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 9px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-right: 15px;
    }
  }

  .btn-delete:hover, .btn-create:hover {
    background: #F7F7F8;
  }

  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 158px;
    height: 48px;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    height: unset !important;
    max-height: unset !important;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .search-actions-wrap {
    display: flex;
    justify-content: space-between;
  }

  .search {
    display: flex;

    &-icon {
      margin-left: 20px;
    }

    &-role {
      margin-left: 20px;
    }
  }

  .md-field {
    width: 289px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 0;
    padding: 0;

    &::after {
      background-color: unset !important;
    }

    .md-input {
      height: unset !important;
      width: 100%;
    }
  }

  .md-select {
    .md-icon-image {
      position: absolute;
      right: 0;
      left: 75%;
      top: 13px;
    }
  }

  .search-select {
    position: absolute;
    left: 15px;
  }

  .md-ripple, .md-table-cell-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;;
  }

  .md-checkbox .md-checkbox-container {
    width: 18px !important;
    min-width: 18px !important;
    height: 18px !important;
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    &:last-child .md-table-cell-container {
      display: flex;
    }
  }


  .md-table-head-container {
    padding-top: 10px !important;
    padding: unset;
  }

  .md-table-head:last-child {
    .md-table-head-label {
      padding-left: 42px !important;
    }
  }

}
</style>
<style lang="scss">
.user-list-wrap {
  .md-table-head-label {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-right: unset !important;
    padding-left: 22px !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>